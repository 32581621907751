import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { useDispatch } from "react-redux";
import firebase from 'firebase/app';
import 'firebase/firestore';   // for cloud firestore
import { firestoreDB } from "dataflow/services/firebase";

import "assets/scss/components/ecard/modalComp.scss";
import { getbooking_success } from "dataflow/slices/booking.slice";

function ModalComp(props) {
  const dispatch = useDispatch();

  const [book, setBook] = useState("");
  const [phone, setPhone] = useState("");
  const [pax, setPax] = useState(0);
  const [relationship, setRelationship] = useState("");

  const handleSubmit = (e) => {
    dispatch(
      getbooking_success({
        displayName: book,
        relationship: relationship,
        session: props.session,
        start: props.start,
        end: props.end,
      })
    );
    e.preventDefault();
    if (props.form) {
      //send rsvp to database
      let batch = firestoreDB().batch()

      let rsvpRef = firestoreDB()
        .collection(props.colId)
        .doc(props.cardId)
        .collection("rsvp")
        .doc(props.id)

      let bookRef = firestoreDB()
        .collection(props.colId)
        .doc(props.cardId)
        .collection("rsvp")
        .doc(props.id)
        .collection("booklist");

      batch.update(rsvpRef, {session: firebase.firestore.FieldValue.increment((pax == 0 || props.unlimited) ? 0 : -pax)})

      batch.set(bookRef.doc(), {
        name: book,
        pax: pax,
        phone: phone,
        relationship: relationship
      });

      // firestoreDB()
      //   .collection(props.colId)
      //   .doc(props.cardId)
      //   .collection("rsvp")
      //   .doc(props.id)
      //   .update({
      //     session: firebase.firestore.FieldValue.increment((pax == 0 || props.unlimited) ? 0 : -pax),
      //     [`list.${book}`]: [pax, phone, relationship],
      //   })
      //   .then(() => console.log("You successfully RSVP"))
      //   .catch((error) => console.log("Failed to RSVP:"));

      batch
        .commit()
        .then(() => {
          console.log("sucesfully update rsvp");
        })
        .catch((error) => {
          console.log("Failed to RSVP:", error);
        });

      props.onHide(true);
    } else {
      props.onHide(false);
    }
  };

  return (
    <Modal
      className="ecard-modal-content"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-center"
      backdrop={!props.form && props.survey ? "static" : true}
      centered
    >
      {props.form ? (
        <Modal.Header className="ecard-modal-header">
          <Modal.Title
            className="ecard-modal-tittle"
            id="contained-modal-title-vcenter"
          >
            {props.session}
          </Modal.Title>
        </Modal.Header>
      ) : (
        <Modal.Header className="ecard-modal-header">
          <Modal.Title
            className="ecard-modal-tittle"
            id="contained-modal-title-vcenter"
          >
            {props.text}
          </Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body className="ecard-modal-body">
        {props.form ? (
          <form onSubmit={handleSubmit}>
            <div class="data">
              <label>Nama Penuh:</label>
              <input
                className="ecardInput"
                type="text"
                required
                placeholder="Aiman Hakim"
                onChange={(e) => setBook(e.target.value)}
              ></input>
            </div>

            {props.attendance && (
              <div class="data">
                <label>Nombor Telefon:</label>
                <input
                  className="ecardInput"
                  type="tel"
                  pattern="^\+?[0-9]?[0-9]{10,11}"
                  required
                  placeholder="0174562895"
                  onChange={(e) => setPhone(e.target.value)}
                ></input>
              </div>
            )}

            {props.attendance && (
              <div class="data">
                <label for="relationship-select">Hubungan:</label>
                <select
                  className="ecardInput"
                  id="relationship-select"
                  required
                  value={relationship}
                  onChange={(e) => setRelationship(e.target.value)}
                >
                  <option className="ecardInput" value="" disabled>
                    --Sila pilih hubungan--
                  </option>
                  <option value="Keluarga">Keluarga</option>
                  <option value="Rakan Universiti">Rakan Universiti</option>
                  <option value="Rakan Sekolah">Rakan Sekolah</option>
                  <option value="Rakan Sekerja">Rakan Sekerja</option>
                  <option value="Jiran">Jiran</option>
                  <option value="Lain-lain">Lain-lain</option>
                </select>
              </div>
            )}

            {props.attendance && (
              <div class="data">
                <label for="attendance-select">Jumlah akan hadir bersama:</label>
                <select
                  className="ecardInput"
                  id="attendance-select"
                  required
                  value={pax}
                  onChange={(e) => setPax(parseInt(e.target.value))}
                >
                  <option className="ecardInput" value="" disabled>
                    --Sila pilih--
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
                {/* <label>Jumlah akan hadir bersama:</label>
                <input
                  className="ecardInput"
                  type="number"
                  required
                  placeholder="2"
                  min="1"
                  max={props.seat < 2 ? props.seat : "2"}
                  onChange={(e) => setPax(parseInt(e.target.value))}
                ></input> */}
              </div>
            )}

            <Button type="submit" variant="primary" size="sm">
              HANTAR
            </Button>
          </form>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="data">
              <label>Nama Penuh:</label>
              <input
                className="ecardInput"
                type="text"
                required
                placeholder="Aiman Hakim"
                onChange={(e) => setBook(e.target.value)}
              ></input>
            </div>
            <div className="data">
              <label htmlFor="relationship-select">Hubungan:</label>
              <select
                className="ecardInput"
                id="relationship-select"
                required
                value={relationship}
                onChange={(e) => setRelationship(e.target.value)}
              >
                <option value="" disabled>
                  --Sila pilih hubungan anda--
                </option>
                <option value="Keluarga">Keluarga</option>
                <option value="Rakan Universiti">Rakan Universiti</option>
                <option value="Rakan Sekolah">Rakan Sekolah</option>
                <option value="Rakan Sekerja">Rakan Sekerja</option>
                <option value="Jiran">Jiran</option>
                <option value="Lain-lain">Lain-lain</option>
              </select>
            </div>
            <Button type="submit" variant="primary" size="sm">
              HANTAR
            </Button>
          </form>
        )}
      </Modal.Body>

      <Modal.Footer className="ecard-modal-footer">
        
      </Modal.Footer>
    </Modal>
  );
}

export default ModalComp;
