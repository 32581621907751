import React from "react";
import { useCustomSelector } from "hooks";
import DetailsDisplay from "./DetailsDisplay";

function Details() {
  
  const { appInfo } = useCustomSelector("app");
  const details = appInfo.details;
  const theme = appInfo.themes;

  const titleStyle = {
    color: theme.contentColor1,
    fontFamily: theme.contentFontFamily1
  }

  const background1 = {
    background: theme.backgroundColor2,
    border:
      theme.border &&
      `solid 3px ${theme.contentColor1}`,
  };

  const style1 = {
    color: theme.detailsColor1,
    fontFamily: theme.detailsFontFamily1
  }

  const style2 = {
    color: theme.detailsColor2,
    fontFamily: theme.detailsFontFamily2
  }

  const style3 = {
    color: theme.detailsColor3,
    fontFamily: theme.detailsFontFamily3
  }

  const placeStyle = {
    color: theme.detailsColor4,
    fontFamily: theme.detailsFontFamily4
  };

  const otherStyle = {
    color: theme.detailsColor5,
    fontFamily: theme.detailsFontFamily5
  }

  const inviteeStyle = {

  };

  const prayerStyle = {
    color: theme?.detailsColor6,
    fontFamily: theme?.detailsFontFamily6
  }

  //need to add to database
  const opacityStyle = {
    opacity: theme?.detailsBgOpacity,
  }

  return (
    <DetailsDisplay
      bgImg={theme.backgroundOptimized2 ? theme.backgroundOptimized2 : theme.backgroundOptimized}
      fallbackImg={theme.background2 ? theme.background2 : theme.background}
      bgOverlayStyle={background1}
      opacityStyle={opacityStyle}

      titleStyle={titleStyle}
      textStyle={otherStyle}

      name1={details.name1}
      name2={details.name2}
      celebratorStyle={style1}

      invitee={details?.invitee}
      inviteeStyle={inviteeStyle}

      spouse1={details.spouse1}
      spouse2={details.spouse2}
      spouseStyle={style2}

      date1={details?.date1? details.date1:appInfo?.home?.date}
      date2={details?.date1? details.date1:appInfo?.home?.date}
      dateStyle={style3}

      place={details?.place? details.place: appInfo?.location?.place}
      placeStyle={placeStyle}

      prayerText={details.dua}
      prayerStyle={prayerStyle} // ISSUE: Not implemented in database yet

      eventType={details?.eventType}
      honoreeTitle={details?.honoreeTitle}
    />
  );
}

export default Details;
